<template>
    
    <div class="details--container" key="arrangements-details">
        <div class="action--bar">
            <div class="container">
                <div class="row">
                    <div class="col-md-12">
                        <router-link to="/arrangementen" tag="a" class="btn btn-secondary btn-icon">
                            <img src=@/assets/img/icons/arrow-left.svg>Terug naar overzicht
                        </router-link>
                    </div>
                </div>
            </div>
        </div>
        <div class="image--block pos-right">
            <div class="image--block-content col-lg-6 col-md-4">
                <background-image image="efteling-winter-2.jpg" prefix=""></background-image>
            </div>
            <div class="container">
                <div class="row section">
                    <div class="col-lg-5 col-md-7">
                        <h3>Gratis kaarten Winter Efteling</h3>
                        <p>
                            Bij reservering vanaf 7 overnachtingen bij B&amp;B De Oude Stoffeerderij in de periode van 11-11-2019 t/m 2-2-2020 ontvangt u per persoon gratis een dagkaartje voor de Winter Efteling.
                        </p>
                        <router-link to="/reserveren" tag="a" class="btn btn-secondary btn-icon">
                            <img src="@/assets/img/icons/calendar-check.svg" />Nu boeken
                        </router-link>
                    </div>
                </div>
            </div>
        </div>
    </div>

</template>

<script>
export default {
    metaInfo: {
        title: 'Gratis kaarten voor de winter Efteling',
        meta: [
            { vmid: 'description', name: 'description', content: 'Bij reservering vanaf 7 overnachtingen bij B&B De Oude Stoffeerderij in de periode van 11-11-2019 t/m 2-2-2020 ontvangt u per persoon gratis een dagkaartje voor de Winter Efteling.' }
        ],
        link: [
            { rel: 'canonical', href: 'https://www.deoudestoffeerderij.nl/arrangementen/winter-efteling'}
        ]
    }
}
</script>
